<template>
    <div>
        <header class="head-nav rflex " :style="{'width':headNavWidth+'px'}" id='header_container'>
            <div class="right-nav" ref="rightNav">
                <div class="left-school rflex" >
                    <span>{{userInfo.licenceName}}</span>
                </div>
<!--                <top-menu></top-menu>-->
                <div class="userinfo-right rflex">

                    <div class="userinfo">
                        <el-menu
                           class="el-menu-demo"
                           mode="horizontal"
                           >


                            <el-submenu index="2"  popper-class="infoItem">
                                <template slot="title">
                                    <div class='welcome'>
                                        <span class="name">您好,</span>
                                        <span class='name avatarname'>{{userInfo.nickName}}</span>
                                    </div>
                                    <img src="../../assets/img/avatar.png" class='avatar' alt="">
                                </template>
                                <el-menu-item index="2-2" @click="setDialogInfo('changePass')">修改密码</el-menu-item>
                                <el-menu-item index="2-3" @click="setDialogInfo('logout')">退出</el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </div>
                </div>

            </div>
        </header>
        <changePassDialog ref="changePassDialog" :changePass="myChangePass"></changePassDialog>
    </div>
</template>

<script>
    import {mapState, mapGetters, mapActions} from 'vuex'
    import topMenu from "./topMenu";
    import * as simpleApi from "request/simple";
    import changePassDialog from "./changePassDialog";

    export default {
        name: 'head-nav',
          data(){
            return{
                myChangePass:{id: null},
                menu:{
                    userBgcolor:'#f0f2f5'
                }
            }
          },
          components:{
            topMenu, changePassDialog
          },
          computed:{
            ...mapGetters('user', ['sidebar']),
            ...mapState({
              userInfo: state => state.user.userInfo,
            }),
            headNavWidth(){
                return document.body.clientWidth - this.sidebar.width
            },
            leftWidth() {
                let p = (document.body.clientWidth * 0.8 - 10) + "px";
                console.log(p);
                return p;
            }

          },
          created(){

          },
          mounted(){
            this.myChangePass.id = this.userInfo.id;
          },
          methods:{
              ...mapActions('user',['setUserInfo']),

              logout(){
                  let that = this;
                  this.$confirm('此操作将退出登录, 是否继续?', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      type: 'warning'
                  }).then(() => {

                      simpleApi.post({controllerName: 'admin', methodName: '/logout', param: { token: this.userInfo.token }}).then(({data, code}) =>  {
                          this.setUserInfo(null);
                      });
                      that.setUserInfo(null);
                      setTimeout(that.$router.push({ name: "login"}), 200);
                  }).catch(() => {

                  });

              },
             /**
             * 弹出框-修改密码或者系统设置
             * @param {string} cmditem 弹框类型
             */
            setDialogInfo(cmditem) {
                switch (cmditem) {
                    case 'changePass':
                        console.log("setDialogInfo, changePass")
                        this.showChangePass();
                        break;
                    case 'logout':
                        this.logout();
                        break;
                }
            },
              showChangePass(){
                  console.log("showChangePass")
                  this.$refs.changePassDialog.showDialog();
              },
            // 切换语言
            changeLocale(type){
                setToken('lang',type);
                this.$i18n.locale = type;
                if(type === 'en'){
                    this.langLogo = this.americaImg;
                }else{
                    this.langLogo = this.chinaImg;
                }
                setToken('langLogo',this.langLogo);
            }
          }
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
    .right-nav{
        display: flex;
        flex: 2;
        width:100px;
        padding-right: 15px;
        justify-content: space-between;
        box-shadow:0px 2px 5px 0px rgba(237,233,233,0.5);
    }
    .left-nav{
        display: flex;
        flex: 1;
        width:calc(80% - 10px);
        justify-content: space-between;
        text-align: center;
        span {
            display:inline-block;
            font-size: 18px;
            padding-top: 20px;
            width: 100%;
            text-align: center;
        }
    }
    .left-school{
        width: 400px;
        justify-content: space-between;
        text-align: center;
        span {
            display:inline-block;
            font-size: 18px;
            padding-top: 20px;
            width: 100%;
            text-align: center;
        }
    }
    .head-nav {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 29;
        transition: width .2s;
        justify-content: space-between;
        height: 60px;
        box-sizing: border-box;
        background: #fff;

        .logout {
            vertical-align: middle;
            cursor: pointer;
        }
    }
    .middle{
       align-items: center;
       border:1px solid;
    }
    .userinfo-right{
        width:300px;
        justify-content: flex-end;
    }
    .userinfo {
        line-height: 60px;
        text-align:right;
    }
    .avatar{
        width: 32px;
        height: 32px;
        border-radius: 50%;
        vertical-align: middle;
        display: inline-block;
    }
    .langAvatar{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        vertical-align: middle;
        display: inline-block;
    }
    .welcome{
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;
        .name{
            line-height: 20px;
            text-align: center;
            font-size: 12px;
        }
        .avatarname{
            color: #083289;
            font-weight:bolder;
            font-size: 13px;
        }
    }

    .username {
        cursor: pointer;
        margin-right: 5px;
    }
    .border{
        border:1px solid;
    }
    .notify-row{
        line-height:60px;
        flex:1;
        ul{
           display: flex;
           justify-content: space-around;
        }
    }

    ul.top-menu > li {
        position: relative;
    }
</style>
