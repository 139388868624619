import request from 'request';

const BASE_URI = '/api/simple';



export async function post(params) {

    let url = params.controllerName + params.methodName;
    return request(url,{
        method: 'post',
        data: params,
    })
}

export async function list(params) {
    let url = params.controllerName + params.methodName;
    return request(url,{
        method: 'post',
        data: params,
    })
}

export async function download(params) {

    return request(BASE_URI + '/download', {
        data: params,
        method: 'post',
        responseType: 'blob'
    });
}

export async function postWait(params) {

    let url = params.controllerName + params.methodName;
    return await request(url,{
        method: 'post',
        data: params,
    })
}

export async function listWait(params) {
    let url = params.controllerName + params.methodName;
    return await request(url,{
        method: 'post',
        data: params,
    })
}



