import {cookieStorage} from '@/common/storage'


const state = {

    pageConfig: {columns: 2, examNoType: 2, examNoTotal: 8, showFoot: true, paperAB: false, },
    tiList: [],
    tiAction: {},
    tiSelect: {},
    tiChange: {},
    isCanSave: false,
    tiIndex: 0,
    isPreview: false,
}

const getters = {
    pageConfig: state => state.pageConfig,
    tiList: state => state.tiList,
    tiAction: state => state.tiAction,
    tiSelect: state => state.tiSelect,
    tiChange: state => state.tiChange,
    tiIndex: state => state.tiIndex,
    isPreview: state => state.isPreview,
    isCanSave: state => state.isCanSave,
}

const mutations = {
    setPageConfig(state, pageConfig) {
        console.log('mutations setPageConfig', pageConfig)
        state.pageConfig = pageConfig || {columns: 2, examNoType: 2, examNoTotal: 8, showFoot: true, paperAB: false, }
    },
    setColumns(state, columns) {
        state.pageConfig.columns = columns;
    },
    setExamNoType(state, examNoType) {
        state.pageConfig.examNoType = examNoType;
    },
    setTiList(state, tiList) {
        state.tiList = tiList || []
    },
    addTi(state, ti) {
        state.tiList.push(ti)
    },
    removeTi(state, idx) {
        state.tiList.splice(idx, 1);
    },
    tiAdd(state, ti) {
        state.tiAction = ti;
    },
    exeSummary(state, id) {
        state.tiAction = { action: 'summary'};
    },
    deleteTi(state, id) {
        state.tiAction = { action: 'delete', id: id};
    },
    changeTiPos(state, o) {
        state.tiAction = { action: 'changePos', id: o.id, dir: o.dir};
    },
    setTiSelect(state, ti) {
        state.tiSelect = ti
    },
    setTiChange(state, ti) {
        state.tiChange = ti
    },
    setTiIndex(state, ti) {
        state.tiIndex = ti
    },
    setIsPreview(state, ti) {
        state.isPreview = ti
    },
    setIsCanSave(state, ti) {
        state.isCanSave = ti
    },

}


const actions = {

    setPageConfig({commit}, pageConfig) {
        commit('setPageConfig', pageConfig)
    },
    setColumns({commit}, v) {
        commit('setColumns', v)
    },
    setExamNoType({commit}, v) {
        commit('setExamNoType', v)
    },
    setTiList({commit}, tiList) {
        commit('setTiList', tiList)
    },
    addTi({commit}, ti) {
        commit('addTi', ti)
    },
    removeTi({commit}, idx) {
        commit('removeTi', idx)
    },
    tiAdd({commit}, ti) {
        commit('tiAdd', ti)
    },
    exeSummary({commit}, id) {
        commit('exeSummary', id);
    },
    deleteTi({commit}, id) {
        commit('deleteTi', id);
    },
    changeTiPos({commit}, o) {
        commit('changeTiPos', o)
    },
    setTiSelect({commit}, ti) {
        commit('setTiSelect', ti)
    },
    setTiChange({commit}, ti) {
        commit('setTiChange', ti)
    },
    setTiIndex({commit}, ti) {
        commit('setTiIndex', ti)
    },
    setIsPreview({commit}, ti) {
        commit('setIsPreview', ti)
    },
    setIsCanSave({commit}, ti) {
        commit('setIsCanSave', ti)
    },
}




export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
