import CryptoJS from "crypto-js"
/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export const getStore = name => {
	if (!name) return;
	var value = window.localStorage.getItem(name);
    if (value !== null) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            value = value;
        }
    }
    return value;
}

/**
 * 删除localStorage
 */
export const removeStore = name => {
	if (!name) return;
	window.localStorage.removeItem(name);
}

/**
 * 让整数自动保留2位小数
 */
// export const returnFloat = value => { 
//     var value=Math.round(parseFloat(value)*100)/100; 
//     var xsd=value.toString().split("."); 
//     if(xsd.length==1){ 
//         value=value.toString()+".00"; 
//         return value;   
//     } 
//     if(xsd.length>1){ 
//         if(xsd[1].length<2){ 
//             value=value.toString()+"0"; 
//         } 
//         return value; 
//     } 
// } 
/**
 * @param {date} 标准时间格式:Fri Nov 17 2017 09:26:23 GMT+0800 (中国标准时间)
 * @param {type} 类型
 *   type == 1 ---> "yyyy-mm-dd hh:MM:ss.fff"
 *   type == 2 ---> "yyyymmddhhMMss"
 *   type == '' ---> "yyyy-mm-dd hh:MM:ss"
 *   type == 3 ---> "yyyy-mm-dd"
 *   type == 4 ---> "yyyy-mm-dd hh:MM"
 */
export const formatDate = (date, type) =>{
    var year = date.getFullYear();//年
    var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;//月
    var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();//日
    var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();//时
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();//分
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();//秒
    var milliseconds = date.getMilliseconds() < 10 ? "0" + date.getMilliseconds() : date.getMilliseconds() //毫秒
    if (type == 1) {
        return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds + "." + milliseconds;
    } else if (type == 2) {
        return year+""+month+""+day+""+hour+""+minutes+""+seconds;
    } else if (type == 3){
        return year + "-" + month + "-" + day;
    } else if (type == 4) {
        return year + "-" + month + "-" + day + " " + hour + ":" + minutes;
    } else {
        return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
    }
}
/**
 * 时间转换：20150101010101 --> '2015-01-01 01:01:01'
 */
export const parseToDate = (timeValue) => {
    var result = "";
    var year = timeValue.substr(0, 4);
    var month = timeValue.substr(4, 2);
    var date = timeValue.substr(6, 2);
    var hour = timeValue.substr(8, 2);
    var minute = timeValue.substr(10, 2);
    var second = timeValue.substr(12, 2);
    result = year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
    return result;
}
/**
 * 判断空值
 */
export const isEmpty = (keys) => {
    if (typeof keys === "string") {
        keys = keys.replace(/\"|&nbsp;|\\/g, '').replace(/(^\s*)|(\s*$)/g, "");
        if (keys == "" || keys == null || keys == "null" || keys === "undefined" ) {
            return true;
        } else {
            return false;
        }
    } else if (typeof keys === "undefined") {  // 未定义
        return true;
    } else if (typeof keys === "number") {
        return false;
    }else if(typeof keys === "boolean"){
        return false;
    }else if(typeof keys == "object"){
        if(JSON.stringify(keys )=="{}"){
            return true;
        }else if(keys == null){ // null
            return true;
        }else{
            return false;
        }
    }

    if(keys instanceof Array && keys.length == 0){// 数组
        return true;
    }

}

/**
 * 返回两位的小数的字符串
 */
export const toFixedNum = (num) => {
    const tonum = Number(num).toFixed(2);
    return tonum;
}

export const showMessage = () =>{
    this.$message({
        showClose: true,
        message: '对不起，您暂无此操作权限~',
        type: 'success'
    });
}

/**
 * 读取base64
 */
export const  readFile = file => {
    console.log(file)
    //var file = this.files[0];
    //判断是否是图片类型
    if (!/image\/\w+/.test(file.raw.type)) {
        alert("只能选择图片");
        return false;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) { 
        var filedata = {
            filename: file.name,
            filebase64: e.target.result
        }
        alert(e.target.result)
    }
}

/**
 * 动态插入css
 */
export const loadStyle = url => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
  }
  /**
   * 设置浏览器头部标题
   */
  export const setTitle = (title) => {
    title = title ? `${title}` : '小爱Admin'
    window.document.title = title
  }

  export const param2Obj = url => {
    const search = url.split('?')[1]
    if (!search) {
      return {}
    }
    return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
  }

  //是否为正整数
export const isInteger = (s) => {
    var re = /^[0-9]+$/ ;
    return re.test(s)
}   

export const setContentHeight = (that,ele,height) => {
    that.$nextTick(() => {
        ele.style.height =   (document.body.clientHeight - height)+'px'
    })
  }


export const searchText = (s) => {
    return s == ''?null:s
}

export const strLeft = (s, len) => {
    return s == ''?null:s.substr(0, len);
}

export const splitDate = (cellValue) => {
    if(cellValue != null && cellValue.length > 7){
        return cellValue.substring(0, 4) + "-" + cellValue.substring(4,6) + "-" + cellValue.substring(6,8);
    }
    return cellValue;
}

export const splitTime = (cellValue) => {
    if(cellValue != null && cellValue.length > 13){
        return cellValue.substring(8, 10) + ":" + cellValue.substring(10,12) + ":" + cellValue.substring(12,14);
    }
    return cellValue;
}

export const urlAddTimestamp = (url) => {
    let getTimestamp=new Date().getTime();
    if(url.indexOf("?")>-1){
        url=url+"&timestamp="+getTimestamp
    }else{
        url=url+"?timestamp="+getTimestamp
    }
    return url;
}

export const acadyearTermList = () => {
    let acadyearTermList = [];
    let now = new Date();
    let year = parseInt(now.getFullYear());
    acadyearTermList.push((year) + '-' + (year+1) + '第二学期');
    acadyearTermList.push((year) + '-' + (year+1) + '第一学期');
    acadyearTermList.push((year-1) + '-' + year + '第二学期');
    acadyearTermList.push((year-1) + '-' + year + '第一学期');
    acadyearTermList.push((year-2) + '-' + (year-1) + '第二学期');
    acadyearTermList.push((year-2) + '-' + (year-1) + '第一学期');
    acadyearTermList.push((year-3) + '-' + (year-2) + '第二学期');
    acadyearTermList.push((year-3) + '-' + (year-2) + '第一学期');

    return acadyearTermList;
}

export const decryptXueZhong = (encryptData) => {
    let key = CryptoJS.enc.Utf8.parse('IOzz0YCbsM8ijmBV')
    let iv = CryptoJS.enc.Utf8.parse('ZZWBKJ_ZHIHUAWEI');
    let decrypt = CryptoJS.AES.decrypt(encryptData, key, {
        iv: iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}

export const suoXiao = (scale) => {
    if(scale > 1){
        let s = scale
        while (true){
            s = s / 10
            if(s < 1) return s
        }
    }
    return scale
}

export const examStatus = (cellValue) => {
    if(cellValue == '00'){
        return '未预处理';
    }else if(cellValue == '01'){
        return '待校对';
    }else if(cellValue == 'SB'){
        return '识别中';
    }else if(cellValue == 'UN'){
        return '未知异常';
    }else if(cellValue == 'YA'){
        return '答题卡缺失';
    }else if(cellValue == 'Y1'){
        return '学号异常';
    }else if(cellValue == 'Y3'){
        return '定位异常';
    }else if(cellValue == 'Y4'){
        return '学号异常';
    }else if(cellValue == 'C1'){
        return '学号异常处理中';
    }else if(cellValue == 'SY'){
        return '第一页异常';
    }else if(cellValue == 'Y5'){
        return '定位点缺失';
    }else if(cellValue == 'Y6'){
        return '切图异常';
    }else if(cellValue == 'Y7'){
        return '试卷类型异常';
    }else if(cellValue == 'Y9'){
        return '学号重复';
    }else if(cellValue == 'TT'){
        return '填涂异常';
    }else if(cellValue == 'F1'){
        return '找不到第一页';
    }else if(cellValue == 'RK'){
        return '入库中';
    }else if(cellValue == 'ZC'){
        return '正常';
    }
    return cellValue == ''?null:cellValue
}


export const getDomain = () => {
    // 获取当前URL
    let urlString = process.env.NODE_ENV === 'production' ? window.g.PROD_HOST : window.g.DEV_HOST;

    // 创建一个URL对象
    const url = new URL(urlString);

    // 获取域名和端口号
    const domain = url.hostname;
    const port = url.port;

    // 如果端口号存在，则返回域名和端口号，否则只返回域名
    return `${url.protocol}//${domain}${port ? ':' + port : ''}`;
};
