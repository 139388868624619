import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui'
// Vue.use(ElementUI, { size: 'mini'});
import permission from './directive/permission'
import './style/vknow.css'
import './style/vknow-iconfont.css'

// import('element-ui/lib/theme-chalk/index.css')

import './components/iconSvg' // iconSvg
import VueTimers from 'vue-timers'
import JsonExcel from 'vue-json-excel'
import VueUUID from 'vue-uuid'
import CryptoJS from "crypto-js"
import VueRouter from "vue-router";

Vue.component('jsonExcel', JsonExcel)
Vue.use(VueTimers)
Vue.use(VueUUID);
Vue.use(CryptoJS);
Vue.use(permission);

Vue.prototype.$ELEMENT = {
  size: 'mini',
  zIndex: 2000
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
