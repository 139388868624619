//存储前缀

import base64 from 'js-base64'

export default new class Base64 {
  constructor() {
    this.prefix = "cubeErp";
    this.base64 = base64.Base64
  }

  //base64加密
  encode(val) {
    return this.base64.encode(base64_prefix + val)
  }

  //base解密
  decode(val) {
    return this.base64.decode(base64_prefix + val)
  }
}
