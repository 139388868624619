import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import mainPage from '../views/main.vue'


Vue.use(VueRouter)

  const routes = [{
    path: '/',
    name: 'root',
    redirect: '/dashboard'

  }, {
      path: '/login',
      name: 'login',
      component: (resolve) => require(['@/views/login'], resolve),
  }, {
    path: '/dashboard',
    name: 'dashboard',
    component: (resolve) => require(['@/views/DashBoard'], resolve),
  }, {
    path: '/scanPre',
    name: 'scanPre',
    component: (resolve) => require(['@/views/scanPre/scanPre'], resolve),
  }, {
    path: '/scoring',
    name: 'scoring',
    component: (resolve) => require(['@/views/exam/scoring/scoring'], resolve),
  }, {
    path: '/cardView',
    name: 'cardView',
    component: (resolve) => require(['@/views/anwCard/cardView'], resolve),
  }, {
    path: '/mainPage', name: 'mainPage', meta: { title: '主页'},
    component: mainPage,
    redirect: '/mainPage/DashBoardInner',
    children: [{
      path: '/mainPage/DashBoardInner', name: 'DashBoardInner', meta: { title: '导航' },
      component: (resolve) => require(['@/views/DashBoardInner'], resolve),
    },{
      path: '/mainPage/zbStudentList', name: 'zbStudentList', meta: { title: '作弊学生列表' },
      component: (resolve) => require(['@/views/sclAdmin/zbStudentList/zbStudentList'], resolve),
    },{
      path: '/mainPage/archiveExamList', name: 'archiveExamList', meta: { title: '试卷归档列表' },
      component: (resolve) => require(['@/views/exam/archiveExamList'], resolve),
    },{
      path: '/mainPage/dataDashBoard', name: 'dataDashBoard', meta: { title: '数据统计' },
      component: (resolve) => require(['@/views/dataDashBoard/dataDashBoard'], resolve),
    },{
      path: '/mainPage/dataDashBoard2', name: 'dataDashBoard2', meta: { title: '数据统计' },
      component: (resolve) => require(['@/views/dataDashBoard/dataDashBoard2'], resolve),
    },{
      path: '/mainPage/schoolList', name: 'schoolList', meta: { title: '二级单位信息'},
      component: (resolve) => require(['@/views/school/schoolList'], resolve),
    },{
      path: '/mainPage/admin2List', name: 'admin2List', meta: { title: '二级单位管理员'},
      component: (resolve) => require(['@/views/admin2/admin2List'], resolve),
    },{
      path: '/mainPage/professionList', name: 'professionList', meta: { title: '专业管理'},
      component: (resolve) => require(['@/views/profession/professionList'], resolve),
    },{
      path: '/mainPage/hardClassList', name: 'hardClassList', meta: { title: '行政班信息'},
      component: (resolve) => require(['@/views/hardClass/hardClassList'], resolve),
    },{
      path: '/mainPage/hardClassUpload', name: 'hardClassUpload', meta: { title: '行政班上传'},
      component: (resolve) => require(['@/views/hardClass/hardClassUpload'], resolve),
    },{
      path: '/mainPage/teacherList', name: 'teacherList', meta: { title: '教师信息'},
      component: (resolve) => require(['@/views/teacher/teacherList'], resolve),
    },{
      path: '/mainPage/courceList', name: 'courceList', meta: { title: '课程信息'},
      component: (resolve) => require(['@/views/cource/courceList'], resolve),
    },{
      path: '/mainPage/teachingClassList', name: 'teachingClassList', meta: { title: '教学班信息'},
      component: (resolve) => require(['@/views/teachingClass/teachingClassList'], resolve),
    },{
      path: '/mainPage/studentList', name: 'studentList', meta: { title: '学生信息'},
      component: (resolve) => require(['@/views/student/studentList'], resolve),
    },{
      path: '/mainPage/allUpload', name: 'allUpload', meta: { title: '综合上传'},
      component: (resolve) => require(['@/views/upload/allUpload'], resolve),
    },{
      path: '/mainPage/myScoreList', name: 'myScoreList', meta: { title: '我的成绩'},
      component: (resolve) => require(['@/views/nyScore/myScoreList'], resolve),
    },{
      path: '/mainPage/scl/courceList', name: 'sclCourceList', meta: { title: '课程信息'},
      component: (resolve) => require(['@/views/sclAdmin/cource/courceList'], resolve),
    },{
      path: '/mainPage/scl/hardClassList', name: 'sclHardClassList', meta: { title: '行政班信息'},
      component: (resolve) => require(['@/views/sclAdmin/hardClass/hardClassList'], resolve),
    },{
      path: '/mainPage/scl/studentList', name: 'sclStudentList', meta: { title: '学生信息'},
      component: (resolve) => require(['@/views/sclAdmin/student/studentList'], resolve),
    },{
      path: '/mainPage/scl/teacherList', name: 'sclTeacherList', meta: { title: '教师信息'},
      component: (resolve) => require(['@/views/sclAdmin/teacher/teacherList'], resolve),
    },{
      path: '/mainPage/scl/teachingClassList', name: 'sclTeachingClassList',  meta: { title: '教学班信息'},
      component: (resolve) => require(['@/views/sclAdmin/teachingClass/teachingClassList'], resolve),
    },{
      path: '/mainPage/tch/courceList', name: 'tchCourceList', meta: { title: '课程信息'},
      component: (resolve) => require(['@/views/sclTeacher/cource/courceList'], resolve),
    },{
      path: '/mainPage/tch/teachingClassList', name: 'tchTeachingClassList', meta: { title: '教学班信息'},
      component: (resolve) => require(['@/views/sclTeacher/teachingClass/teachingClassList'], resolve),
    },{
      path: '/mainPage/tch/myClassList', name: 'tchMyClassList', meta: { title: '我的班级'},
      component: (resolve) => require(['@/views/sclTeacher/myClass/myClassList'], resolve),
    },{
      path: '/mainPage/tch/myStudentList', name: 'tchMyStudentList', meta: { title: '我的学生'},
      component: (resolve) => require(['@/views/sclTeacher/myStudent/myStudentList'], resolve),
    },{
      path: '/mainPage/tch/examDownloadList', name: 'tchExamDownloadList', meta: { title: '归档下载'},
      component: (resolve) => require(['@/views/sclTeacher/examDownload/ExamDownloadList'], resolve),
    },{
      path: '/mainPage/exceptPage', name: 'exceptPage', meta: { title: '异常卷'},
      component: (resolve) => require(['@/views/exceptPage/exceptPage'], resolve),
    },{
      path: '/mainPage/teacher/myExam', name: 'tchMyExam', meta: { title: '我的阅卷'},
      component: (resolve) => require(['@/views/sclTeacher/myExam/myExam'], resolve),
    },{
      path: '/mainPage/anwCard/anwCardList', name: 'anwCardList', meta: { title: '答题卡'},
      component: (resolve) => require(['@/views/anwCard/anwCardList'], resolve),
    },{
      path: '/mainPage/examsList', name: 'examsList', meta: { title: '成绩单'},
      component: (resolve) => require(['@/views/exams/examsList'], resolve),
    },{
      path: '/mainPage/missingExamsList', name: 'missingExamsList', meta: { title: '成绩单'},
      component: (resolve) => require(['@/views/exams/missingExamsList'], resolve),
    },{
      path: '/mainPage/downloadList', name: 'downloadList', meta: { title: '归档下载'},
      component: (resolve) => require(['@/views/download/downloadList'], resolve),
    },{
      path: '/mainPage/examsAnalyse', name: 'examsAnalyse', meta: { title: '试卷分析'},
      component: (resolve) => require(['@/views/exams/examsAnalyse'], resolve),
    },{
      path: '/mainPage/cef/scan', name: 'cefScan', meta: { title: '扫描试卷'},
      component: (resolve) => require(['@/views/cefClient/scan'], resolve),
    },
    //   {
    //   path: '/mainPage/cef/scanPre', name: 'scanPre', meta: { title: '异常'},
    //   component: (resolve) => require(['@/views/cefClient/scan'], resolve),
    // },
      {
      path: '/mainPage/scanPreResult', name: 'scanPreResult', meta: { title: '已识别试卷'},
      component: (resolve) => require(['@/views/scanPreResult/scanPreResult'], resolve),
    },{
      path: '/mainPage/historyScore', name: 'historyScore', meta: { title: '历史成绩'},
      component: (resolve) => require(['@/views/historyScore/historyScore'], resolve),
    },{
      path: '/mainPage/hardAnalyList', name: 'hardAnalyList', meta: { title: '班级统计'},
      component: (resolve) => require(['@/views/adminClass/hardAnalyList'], resolve),
    // },{
    //   path: '/mainPage/scanPreResultOld', name: 'scanPreResultOld', component: scanPreResultOld, meta: { title: '已识别试卷(旧)'},
    //   component: (resolve) => require(['@/views/DashBoardInner'], resolve),
    },{
      path: '/mainPage/report/degreelReport', name: 'degreelReport',  meta: { title: '达成度'},
      component: (resolve) => require(['@/views/report/degreelReport'], resolve),
    },{
      path: '/mainPage/degree/degreeList', name: 'degreeList', meta: { title: '达成度'},
      component: (resolve) => require(['@/views/degree/degreeList'], resolve),
    },{
      path: '/mainPage/exam/newExam', name: 'newExamList',  meta: { title: '新建考试'},
      component: (resolve) => require(['@/views/exam/newExam/newExamList'], resolve),
    },{
      path: '/mainPage/exam/examHis', name: 'examHisList', meta: { title: '阅卷记录'},
      component: (resolve) => require(['@/views/exam/examHis/examHisList'], resolve),
    },{
      path: '/mainPage/softList', name: 'softList', meta: { title: '软件下载'},
      component: (resolve) => require(['@/views/softList'], resolve),
    }]
  }, {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//路由开始之前的操作
router.beforeEach((to, from, next) => {

  let toName = to.name;
  let toPath = to.path;
  // let fromName = from.name

  let isLogin = store.state.user && store.state.user.userInfo && store.state.user.userInfo.id
  // console.log('isLogin', isLogin, 'toName', toName, store)
  if(!isLogin && toName != 'login'){
    next({ name: 'login' })
  }else{
    if(isLogin && toName == 'login'){
      next({ name: 'root' })
    }else{
      next();
    }

  }
})
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
export default router
