<template>
    <div class="changePassDialog">
        <el-dialog title="修改密码" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="changePassForm" ref="changePassDialogForm" :rules="changePassRules">
                <el-form-item label="旧密码"  prop="pass" :label-width="formLabelWidth">
                    <el-input v-model="changePassForm.pass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码"  prop="newPass" :label-width="formLabelWidth">
                    <el-input v-model="changePassForm.newPass" type="password" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码"  prop="pass" :label-width="formLabelWidth">
                    <el-input v-model="changePassForm.passConfirm" type="password" autocomplete="off"></el-input>
                </el-form-item>
                
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'changePassDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, loading: false,
                roleList: [], selRoleList: [],
                changePassForm:{ id: null, pass: null, newPass: null, passConfirm: null,},
                changePassRules: {
                    passConfirm: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }],
                    newPass: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
                    pass: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
        },
      	mounted() {

	    },
        props: {
            changePass: {
                type: Object
            }
        },
        watch: {
            changePass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.changePassForm = { id: n.id, pass: null, newPass: null, passConfirm: null, };
                    }else{
                        this.changePassForm = { id: null, pass: null, newPass: null, passConfirm: null,};
                    }

                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){


                let that = this;
                this.$refs.changePassDialogForm.validate((valid) => {
                    if(valid){
                        if(this.changePassForm.newPass != this.changePassForm.passConfirm){
                            this.$message.error("两次密码不一致");
                            return ;
                        }
                        this.loading = true;
                        let param = { controllerName: 'licence', methodName: '/changePass', param: this.changePassForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('changePass', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                this.$message.success("修改成功");
                                that.$emit('ok');
                            }else if (code == -10) {
                                this.$message.error("原密码错误");
                            }
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .changePassDialog{
        z-index:999;
    }


</style>


