import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import user from './user'
import card from "./card";
import scanExam from "./scanExam";

export default new Vuex.Store({
  modules: {
    user, card,scanExam
  }
})
