import {cookieStorage} from '@/common/storage'



const state = {
    scanExamInfo: cookieStorage.get('vknowScanExam'),

}


const actions = {

    //设置用户信息和登录
    // set_user_info({commit}, userinfo){
    //     commit('setUserInfo', userinfo)
    // },

    // setUserInfo({commit}, userinfo){
    //     commit('setUserInfo', userinfo)
    // },
    // setToken({commit}, token){
    //     commit('setToken', token)
    // },
    // setUserName({commit}, token){
    //     commit('setUserName', token)
    // }
}

const getters = {
    sidebar:state => state.sidebar,
    isCollapse:state => state.isCollapse,
    isFooter:state => state.isFooter,
    // //获取用户信息
    // getUserInfo: state => {
    //     return state.userInfo
    // },
    // getToken: state => {
    //     return state.token
    // },
    // getUserName: state => {
    //     return state.userName
    // },
    // isContainRoles: (state, roleKey) => {
    //     if(state.userInfo && state.userInfo.roleKeys){
    //         let list = state.userInfo.roleKeys;
    //         for(let i=0; i<list.length; i++){
    //             if(roleKey == list[i]){
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // }
}

const mutations = {

    // [types.HANDLE_LEFT_MENU] (state) {
    //     if(state.sidebar.opened){//true
    //         state.sidebar.width = state.minLeftMenuWidth;
    //     }else{
    //         state.sidebar.width = state.maxLeftMenuWidth;
    //     }
    //     state.sidebar.opened = !state.sidebar.opened
    // },
    // [types.INIT_LEFT_MENU] (state) {
    //     state.sidebar = state.sidebar
    // },
    // [types.SET_LEFT_COLLAPSE] (state) {
    //     state.isCollapse = !state.isCollapse
    // },
    // [types.SET_FOOTER_SHOW] (state) {
    //     state.isFooter = true
    // },

    // setUserInfo(state, userinfo){
    //     console.log('enter mutations SET_USER_INFO', userinfo)
    //     state.userInfo = userinfo || {}
    //     if (userinfo === null) {
    //         cookieStorage.remove('vknowInfo')
    //     } else {
    //         cookieStorage.set('vknowInfo', userinfo)
    //     }
    // },
    // setToken(state, token){
    //     console.log('enter mutations setToken', token)
    //     state.token = token || {}
    //     if (token === null) {
    //         cookieStorage.remove('vknowToken')
    //     } else {
    //         cookieStorage.set('vknowToken', token)
    //     }
    // },
    // setUserName(state, userName){
    //     console.log('enter mutations userName', userName)
    //     state.userName = userName || {}
    //     if (userName === null) {
    //         cookieStorage.remove('vknowUserName')
    //     } else {
    //         cookieStorage.set('vknowUserName', userName)
    //     }
    // },
    // setBreadcrumb(state, breadcrumb){
    //     state.breadcrumb = breadcrumb;
    // },
    // setAction(state, action){
    //     state.action = action;
    // },
    // setDeviceSearch(state, deviceSearch){
    //     state.deviceSearch = deviceSearch;
    // },

}

export default {
    namespaced:true,
    state,
    actions,
    getters,
    mutations
}
