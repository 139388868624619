<template>
  <div class="home rflex">
    <left-menu></left-menu>
    <div class="menu_right wflex el-scrollbar" ref="menu_right" :style="{left:sidebar.width+'px'}">
      <head-nav></head-nav>
      <div class="menu_content" ref="menu_content">
        <bread></bread>
        <router-view></router-view><!--页面渲染入口-->
      </div>
      <backTop :ele="$refs.menu_right"></backTop>
      <footerNav></footerNav>
    </div>
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'

  import HeadNav from '../components/layout/headNav.vue';
  import LeftMenu from '../components/layout/leftMenu.vue';
  import Bread from '../components/layout/bread.vue';
  import backTop from '../components/backTop';
  import footerNav from '../components/layout/footerNav';

  export default {
    name: 'home',
    data(){
      return {
      }
    },
    computed:{
      ...mapGetters('user', ['sidebar']),
    },
    components:{
      HeadNav,
      LeftMenu,
      Bread, footerNav,
      backTop
    },
    created() {
    },
    mounted (){
    },
    watch:{

    }
  }
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss">
  .home{
    .menu_right{
      overflow: auto;
      position: absolute;
      right:0;
      top:0;
      bottom:0;
      background:#F6F7FC;
      .menu_content{
        position: relative;
        margin-top:60px;
        width:100%;
        background:#f0f2f5;
      }

    }
  }
</style>
