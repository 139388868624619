<template>
<!--	 <div class='footer' v-if="myHost=='www.51obe.com'">-->
<!--        <p class="beian">-->
<!--          <a href="https://beian.miit.gov.cn" target="_blank" style="color: white;">闽ICP备19020791号</a>-->
<!--          copy-right 2021 福州市广思聚文科技有限公司</p>-->
<!--    </div>-->
</template>


<script>

export default {
    name: "footerNav",
	data(){
		return {
            myHost: null,
		}
	},
    mounted() {
        this.myHost = window.location.host;
    },
    methods:{

	}
}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
	.footer{

        height:100px;   /* footer的高度一定要是固定值*/
        position:absolute;
        bottom:0px;
        left:0px;
        background: transparent;
        width: 100%;
        padding: 10px 0;
        font-size:12px;
        text-align: center;
        p{
            line-height: 30px;
        }
        .beian{
            color: white;
            margin: 0 auto;
            justify-content: space-between;
            align-items: center;
        }
    }
</style>


