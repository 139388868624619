<template>
   <div class="menu_left cflex" :style="{width:sidebar.width+'px'}">
        <div class="menu_page_top rflex">
            <img :class='["logo",{"closeLogo":!sidebar.opened}]' src="../../assets/logo_small.png" alt="vknow" >
            <span class='title' v-show="sidebar.opened">阅卷</span>
        </div>
        <div class="menu_page_bottom is-scroll-left">
            <el-menu
                mode="vertical"
                theme="dark"
                :show-timeout="200"
                :default-active="$route.path"
                :collapse="isCollapse"
                :background-color="menuObj.bgColor"
                :text-color="menuObj.textColor"
                :active-text-color="menuObj.activeTextColor"
                :style="{width:sidebar.width+'px'}"
                >
                    <template v-for="(item,index) in userData">
                        <!--表示 有一级菜单-->
                        <div v-if="!item.children || item.children.length == 0">
                            <router-link v-if="!item.url || item.url == ''" :to="item.path" :key="index">
                                <el-menu-item class="dropItem" :index="item.path">
                                    <i v-if="item.icon" :class="item.icon"></i>
                                    <span v-if="item.name" slot="title">{{ item.name}}</span>
                                </el-menu-item>
                            </router-link>
                            <a v-else target="_blank" :href="item.url">
                                <el-menu-item class="dropItem" :index="item.path">
                                    <i v-if="item.icon" :class="item.icon"></i>
                                    <span v-if="item.name" slot="title">{{ item.name}}</span>
                                </el-menu-item>
                            </a>
                        </div>


                        <!--表示 有二级或者多级菜单 -->
                        <el-submenu v-if="item.children && item.children.length >= 0" :index="'item-'+index" :key="index" class="dropItem">
                            <template slot="title">
                                <i v-if="item.icon" :class="item.icon"></i>
                                <span v-if="item.name" slot="title">{{item.name}}</span>
                            </template>
                            <!--直接定位到子路由上，子路由也可以实现导航功能-->
                            <div v-for="(citem,cindex) in item.children">

                                <router-link :target="citem.target?'_blank':'_self'" v-if="!citem.url || citem.url == ''" :to="citem.path"  :key="cindex">
                                    <el-menu-item :index="citem.path">
                                        <span slot="title"> {{citem.name}}</span>
                                    </el-menu-item>
                                </router-link>
                                <a v-else target="_blank" :href="citem.url">
                                    <el-menu-item :index="citem.path">
                                        <span slot="title"> {{citem.name}}</span>
                                    </el-menu-item>
                                </a>
                            </div>
                        </el-submenu>
                    </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import XEUtils from "xe-utils";


export default {
  name: "left-Menu",
  data() {
    return {
        isInCefClient: false,
       menuObj:{
         bgColor:'#fff',
         textColor:'#666',
         activeTextColor:'#02b6fd',
       },

        data: [{
            name: "主页", path: "/", icon: "vknowfont vicon-shouye", allows: ['admin', 'sclAdmin', 'teacher', 'student'],
        }, {
            name: "基础信息管理", icon: "vknowfont vicon-svg-",  allows: ['admin'],
            children: [
                {
                    name: "二级单位信息", icon: "el-icon-user", path: "/mainPage/schoolList", allows: ['admin'],
                }, {
                    name: "二级单位管理员", icon: "el-icon-user", path: "/mainPage/admin2List", allows: ['admin'],
                }, {
                    name: "专业管理", icon: "el-icon-microphone", path: "/mainPage/professionList", allows: ['admin'],
                }
            ]
        }, {
            name: "用户管理", icon: "vknowfont vicon-yonghu", path: "/mainPage/schoolList", allows: ['admin'],
            children: [{
                name: "学生信息", icon: "el-icon-help", path: "/mainPage/studentList", allows: ['admin'],
            }, {
                name: "教师信息", icon: "el-icon-s-order", path: "/mainPage/teacherList", allows: ['admin'],
            }, {
                name: "行政班信息", icon: "el-icon-upload", path: "/mainPage/hardClassList", allows: ['admin'],
            }, {
                name: "课程信息", icon: "el-icon-s-tools", path: "/mainPage/courceList", allows: ['admin'],
            }, {
                name: "教学班信息", icon: "el-icon-help", path: "/mainPage/teachingClassList", allows: ['admin'],
            }, {
                name: "综合上传", icon: "el-icon-upload2", path: "/mainPage/allUpload", allows: ['admin'],
            }]
        }, {
            name: "作弊学生列表", icon: "vknowfont vicon-ceshi", path: "/mainPage/zbStudentList", allows: ['admin'],
        }, {
            name: "试卷归档", icon: "vknowfont vicon-guidang", path: "/mainPage/archiveExamList", allows: ['admin'],
        }, {
          name: "班级统计", icon: "vknowfont vicon-guidang", path: "/mainPage/hardAnalyList", allows: ['admin'],
        }, {
          name: "已识别试卷", icon: "el-icon-help", path: "/mainPage/scanPreResult", allows: ['admin'],
        }, {
          name: "历史成绩", icon: "el-icon-help", path: "/mainPage/historyScore", allows: ['admin'],
        }, {
            name: "数据统计", icon: "vknowfont vicon-tongji", path: "/mainPage/dataDashBoard", allows: ['admin'],
        // 单位管理员
        }, {
            name: "用户管理", icon: "vknowfont vicon-yonghu", path: "/mainPage/schoolList", allows: ['sclAdmin'],
            children: [{
                name: "学生信息", icon: "el-icon-help", path: "/mainPage/scl/studentList", allows: ['sclAdmin'],
            }, {
                name: "教师信息", icon: "el-icon-s-order", path: "/mainPage/scl/teacherList", allows: ['sclAdmin'],
            }, {
                name: "行政班信息", icon: "el-icon-upload", path: "/mainPage/scl/hardClassList", allows: ['sclAdmin'],
            }, {
                name: "课程信息", icon: "el-icon-s-tools", path: "/mainPage/scl/courceList", allows: ['sclAdmin'],
            }, {
                name: "教学班信息", icon: "el-icon-help", path: "/mainPage/scl/teachingClassList", allows: ['sclAdmin'],
            }, {
                name: "综合上传", icon: "el-icon-upload2", path: "/mainPage/allUpload", allows: ['sclAdmin'],
            }]
        }, {
            name: "数据统计", icon: "vknowfont vicon-tongji", path: "/mainPage/dataDashBoard2", allows: ['sclAdmin'],
            // 教师
        }, {
            name: "基础信息", icon: "vknowfont vicon-svg-", path: "/mainPage/schoolList", allows: ['teacher'],
            children: [{
                name: "课程信息", icon: "el-icon-s-tools", path: "/mainPage/tch/courceList", allows: ['teacher'],
            }, {
                name: "教学班信息", icon: "el-icon-help", path: "/mainPage/tch/teachingClassList", allows: ['teacher'],
            }, {
                name: "我的班级成绩", icon: "el-icon-upload", path: "/mainPage/tch/myClassList", allows: ['teacher'],
            }, {
                name: "我的学生", icon: "el-icon-s-custom", path: "/mainPage/tch/myStudentList", allows: ['teacher'],
            }]
        }, {
            name: "笔试考试", icon: "vknowfont vicon-gangbi", path: "/mainPage/exam/newExam", allows: ['teacher', 'sclAdmin', 'admin'],
        },{
            name: "答题卡", icon: "vknowfont vicon-datiqia", url: "/anwcard/#/list/index", allows: ['teacher'],


        }, {
            name: "扫描", icon: "vknowfont vicon-saomiaoyi", path: "/mainPage/schoolList", allows: ['teacher'],
            children: [{
                name: "扫描答题卡", icon: "el-icon-help", path: "/mainPage/cef/scan", allows: ['teacher'],
            },
              // {
              //   name: "异常", icon: "el-icon-help", path: "/scanPre?a=211",  target: "new", allows: ['teacher'],
              // },
              {
                name: "试卷预处理", icon: "el-icon-help", path: "/scanPre", target: "new", allows: ['teacher'],
            }, {
                name: "已识别试卷", icon: "el-icon-help", path: "/mainPage/scanPreResult", allows: ['teacher'],
            // }, {
            //     name: "已识别试卷(旧)", icon: "el-icon-help", path: "/mainPage/scanPreResultOld", allows: ['teacher'],

            // }, {
            //     name: "缺考学生", icon: "el-icon-help", path: "/mainPage/missingExamsList", allows: ['teacher'],
            }]

        }, {
            name: "我的成绩", icon: "vknowfont vicon-chengjichaxun-", path: "/mainPage/myScoreList", allows: ['student'],

        }, {
            name: "成绩管理", icon: "vknowfont vicon-chengjichaxun-", path: "/mainPage/schoolList", allows: ['teacher'],
            children: [{
                name: "成绩单", icon: "el-icon-help", path: "/mainPage/examsList", allows: ['teacher'],
            }, {
                name: "试卷分析", icon: "el-icon-help", path: "/mainPage/examsAnalyse", allows: ['teacher'],
            }, {
                name: "归档下载", icon: "el-icon-help", path: "/mainPage/downloadList", allows: ['teacher'],
            // }, {
            //     name: "总评表", icon: "el-icon-help", path: "/mainPage/report/overallReport", allows: ['teacher'],
            // }, {
            //     name: "达成度", icon: "el-icon-help", path: "/mainPage/degree/degreeList", allows: ['teacher'],
            }]
        // },{
        //   name: "数据分析", icon: "vknowfont vicon-mubiaoyudacheng", url: "https://www.51obe.com/analyse/#/login?t=normal", allows: ['teacher', 'sclAdmin', 'admin'],
        // }, {
        //     name: "达成度", icon: "vknowfont vicon-mubiaoyudacheng", path: "/mainPage/degree/degreeList", allows: ['teacher'], hosts: ['www.51obe.com'],
        }, {
            name: "软件下载", icon: "vknowfont vicon-ceshi", path: "/mainPage/softList", allows: ['teacher'],
        // }, {
        //     name: "总评表管理", icon: "el-icon-user", allows: ['teacher'],
        //     children: []
        }],
        userData: [],
    };
  },
    // watch: {
    //     $route:  {
    //         immediate: true,
    //         handler(newVal, oldval){
    //             console.log('route watch', newVal, oldval);
    //         },
    //         // 深度监听
    //         deep: true
    //     }
    // },
  computed:{
      ...mapGetters('user', [
          'permission_routers',
          'isCollapse',
          'sidebar',
          'menuIndex'
      ]),
      ...mapState({
          userInfo: state => state.user.userInfo,
          scanExamInfo: state=>state.scanExam.scanExamInfo,
      }),
  },
  created(){
  },
  mounted(){
      if(typeof scanManager == 'undefined') {
          this.isInCefClient = false;
      }else{
          this.isInCefClient = true;
      }
      this.loadUserMenu();
  },
  methods: {
      isInHost(h){
          let host = window.location.host;
          // console.log('host', host);
          return h == host;
      },
      loadUserMenu(){
          console.log('host', window.location.host);
          let role = this.userInfo.role;
          this.userData = [];
          let that = this;
          for (let i=0; i<this.data.length; i++){
              let d = this.data[i];
              if(this.isInCefClient){
                  if(d.name === "扫描"){
                      for (let j=d.children.length-1; j>=0; j--){
                          let child = d.children[j];
                          //if(child.path && (child.path !== "/mainPage/cef/scan" &&  child.path !== "/scanPre?a=211")){
                        if(child.path && child.path !== "/mainPage/cef/scan" ){
                              d.children.splice(j,1);
                          }
                      }
                      this.userData.push(d);
                  }
              }else{
                  let allows = d.allows;
                  let hosts = d.hosts;
                  if(hosts && hosts.length > 0){
                      let ht = XEUtils.find(hosts, item => that.isInHost(item));
                      if(!ht) continue;
                  }
                  let ar = XEUtils.find(allows, item => {
                      return item == role;
                  });
                  if(ar){
                      if(d.children && d.children.length > 0){
                          for (let j=d.children.length-1; j>=0; j--){
                              let child = d.children[j];
                            // if(child.path && (child.path === "/mainPage/cef/scan" || child.path === "/scanPre?a=211")){
                            if(child.path && child.path === "/mainPage/cef/scan" ){
                                  console.log("path", d.path);
                                  if(!this.isInCefClient){
                                      d.children.splice(j,1);
                                  }
                              }
                          }
                      }
                      this.userData.push(d);
                  }
              }
          }
      }
  }
};
</script>


<style lang="scss" type="text/scss" rel="stylesheet/scss">
  $left-bgColor:#fff;  // 左侧菜单背景颜色;
  $icon-link:#FF6C60;
    .menu_left{
        position: absolute;
        top:0;
        left:0;
        bottom:0;
    }
    .menu_page_top{
        width:100%;
        height: 60px;
        align-items: center;
        justify-content: space-around;
        text-transform: uppercase;
        box-sizing: border-box;
        box-shadow:0px 2px 5px 0px rgba(230,224,224,0.5);
        .logo {
            height: 36px;
            width: 36px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 10px;
        }
        .closeLogo{
            width:30px;
            height:30px;
        }
        .title{
            font-size: 22px;
            background-color: transparent;
            border: none;
            color: black;
            i{
                color:#02b6fd;
            }
        }
    }
  .menu_page_bottom {
      width:100%;
      overflow-y: scroll;
      overflow-x: hidden;
      flex:1;
      margin-top:3px;
      z-index: 10;
      box-shadow: 0 0 10px 0 rgba(230, 224, 224, 0.5);
      .vknowfont {
          margin-right: 8px;
      }
  }
</style>
