<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  import * as simpleApi from "request/simple";

  export default {
    name: 'app',
    mounted(){
      document.getElementById('app').style.display = 'block';
      document.getElementById('appLoading').style.display = 'none';
      this.loadGitVersion().then(ret => {
        console.log('git version', ret)
      })
    },
    methods: {
      async loadGitVersion() {
        let param = {controllerName: '/public', methodName: '/getGitVersion', param: {}};
        let ret = await simpleApi.post(param)
        if (ret.code === 0) {
          return {
            'sso': ret['result'].data,
            'vknow': JSON.parse(process.env.GIT_VERSION)
          }
        }
      }
    }
  }
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss">
  @import './style/variables';
  @import './style/element-ui';
  @import './style/common';
  @import './style/scrollBar';
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>