import axios from 'axios';
import store from '../store';
import router from '@/router'
import XEUtils from 'xe-utils'

axios.defaults.timeout = window.g.AXIOS_TIMEOUT; //30000
//http://localhost:9080/  http://sso.icubespace.com:9080/" http://218.17.101.106/
//development production
// axios.defaults.baseURL = process.env.NODE_ENV=='production'?"http://talkback.szpcyl.com/java/web/":"http://localhost:9086/web/";
// axios.defaults.baseURL = process.env.NODE_ENV=='production'?"http://218.17.101.106/java/web/":"http://localhost:9086/web/";
axios.defaults.baseURL = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
axios.defaults.headers = {
    'Company-url': 'http://www.szpcyl.com',
    'X-Powered-By': 'raozq',
    // 'My-Email': '332899@qq.com',
    // 'My-Tel': '13926539075',
    'ke-Token': 'noLogin',
    'ke-Domain': 'WB',
    'Content-Type':'application/json;charset=UTF-8'
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
}

const setUserInfo = function (user) {

    store.dispatch('user/setUserInfo', user)
}
const getToken = function () {
    let myToken = XEUtils.isString(store.state.user.token) ;
    if(myToken){
        return store.state.user.token;
    }
    return 'noLogin';
}

const getUser = function () {

    if(store.state.user && store.state.user.userInfo){

        let userId = XEUtils.isString(store.state.user.userInfo.id) ;
        if(userId){
            return store.state.user.userInfo;
        }
    }
    return null;
}


function checkResponse(response){
    if (response.data) {
        // 大于1000 的是业务错误，弹框
        switch (response.data.code) {
            case 200:
                return response.data;
                break;
            case 401:
                // 清空state
                store.dispatch('logout');
                break;
            case 403:
                // 清空state
                store.dispatch('logout');
                break;
            case 1001:
                let message = '';
                let errors = response.data.payload.errors;
                for(let errorIndex in errors)
                {
                    for (let index in errors[errorIndex])
                    {
                        message += errors[errorIndex][index];
                    }
                }
                ELEMENT.Message({
                    title: '警告',
                    message: `[${response.data.code}] ${response.data.message}`,
                    type: 'warning'
                });
                break;
            default:
                ELEMENT.Message({
                    title: '警告',
                    message: `[${response.data.code}] ${response.data.message}`,
                    type: 'warning'
                });
                break;
        }
        return response.data;
    }
}

export default function request(url, options)
{
    let myToken = getToken();
    // console.log('mytoken', myToken)
    options.url = url;
    axios.defaults.headers = {
        'Company-url': 'http://www.szpcyl.com',
        'X-Powered-By': 'raozq',
        'ke-Token': myToken,
        'ke-Domain': 'WB',
        'Content-Type':'application/json;charset=UTF-8'
    }

    // 判断本地是否存储有Token，自动携带
    if (myToken && myToken != 'noLogin') {
        axios.defaults.headers['ke-Token'] = myToken;
        let myUser = getUser();

        if (options.method.toLowerCase() == 'get') {
            // options.data.token = myToken;
            // if(myUser) options.data.token = myToken;
        } else {
            options.data.token =  myToken;
        }
    }

    return new Promise((resolve, reject) => {
        // https://github.com/mzabriskie/axios

        // console.log('request', options);
        //请求处理
        return axios.request(options).then(({data: {code, msg, result}}) => {
            // console.log("request", code, msg, result)
            //请求成功时,根据业务判断状态
            if (code === 0) {
                resolve({code, msg, result})
                return false
            } else if (code === -1) {
                ELEMENT.Message({title: '错误', message: '系统错误！', type: 'error'});
                reject({code, msg, result})
                return false
            } else if (code === 555) {
                ELEMENT.Message({title: '错误', message: msg, type: 'error'});
                reject({code, msg, result})
                return false
            } else if (code === -99) {
                setUserInfo(null)
                router.replace({name: "root"})
            }
            ELEMENT.Message({title: '错误', message: '登录超时，请重新登录！', type: 'error'});
            reject({code, msg, result})
        })
            .catch((error) => {
                //请求失败时,根据业务判断状态
                if (error.response) {
                    let resError = error.response
                    let resCode = resError.status
                    let resMsg = error.message
                    ELEMENT.Message({title: '请求超时', message: resMsg, type: 'error'});
                    reject({code: resCode, msg: resMsg})
                }
            });
    })
}

export function requestStream(url, options)
{
    let myToken = getToken();
    options.url = url;
    // 判断本地是否存储有Token，自动携带
    if (myToken && myToken != 'noLogin') {
        axios.defaults.headers['ke-Token'] = myToken;
        let myUser = getUser();

        if (options.method.toLowerCase() == 'get') {
            options.params.token = myToken;
            if(myUser) options.params.token = myToken;
        } else {
            options.data.token =  myToken;
        }
    }
    // options.data =  Qs.stringify(options.data);
    return new Promise((resolve, reject) => {
        // https://github.com/mzabriskie/axios

        // console.log('request', options);
        //请求处理
        Object.assign(options,{responseType: 'arraybuffer'})
        return axios.request(options).then((data) => {
                resolve('data:image/png;base64,'+btoa(new Uint8Array(data.data).reduce((data, byte) => data + String.fromCharCode(byte), '')))
           })
            .catch((error) => {
                //请求失败时,根据业务判断状态
                // if (error.response) {
                //     let resError = error.response
                //     let resCode = resError.status
                //     let resMsg = error.message
                //     notification['error']({message: '请求超时',  description: resMsg,  duration: 2});
                //     reject({code: resCode, msg: resMsg})
                // }
            });
    })
}
